import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as models from '../../../services/model/models';
import { IMyDpOptions, IMyDateModel, IMyDateRange } from 'mydatepicker';
import { SharedDataService } from '../../../services/common/shared-data.service';

@Component({
  selector: 'vehicle-certification-report-search-toolbar',
  templateUrl: 'vehicle-certification-report-search-toolbar.component.html',
  styleUrls: ['./vehicle-certification-report-search-toolbar.component.scss']
})

export class VehicleCertificationReportSearchToolbarComponent implements OnInit{

  @Output() viewInventoryEvent = new EventEmitter<any>();

  @Output() findDealerEvent = new EventEmitter<any>();

  @Output() findVehicleEvent = new EventEmitter<any>();

  @Input() enteredDealerCode: string;

  @Output() filterDateEvent = new EventEmitter<any>();

  @Input() enteredvin: string;

  @Input() fromDate: string;

  @Input() toDate: string;

  @Input() labelText: string = 'Certification Date';

  @Input() regionSelection: string;

  @Input() districtSelection: string;
  @Input() isRegionalAndDistrictWiseFilterSelected: boolean = false;
  @Input() isRegionFilter: boolean = false;
  @Output() selctedRegionForRegionaDistrictWiseEvent = new EventEmitter<any>();
  myDatePickerOptions: IMyDpOptions = {
    dateFormat: 'mm/dd/yyyy',
    editableDateField: false,
    openSelectorOnInputClick: true,
    inline: false,
    openSelectorTopOfInput: false,
    showClearDateBtn: true,
    showInputField: true,
    alignSelectorRight: true,
    showSelectorArrow: false,
    disableWeekends: false,
    disableDateRanges: []     
  };

 

  filterMethoditems: Array<any> =
    [
      { name: 'Show All', value: 'Show All', disabled: false },
      { name: 'Current Week', value: 'Current Week', disabled: false },
      { name: 'Current Month', value: 'Current Month', disabled: false },
      { name: 'Current Date', value: 'Current Date', disabled: true }
    ];

  @Input() isDateSelected: boolean = false;
  regionItems: Array<any>;
  districtItems: Array<any>;
  constructor(public sharedDataService: SharedDataService) {
    

    // let regionArrayCopy = new Array<any>();
    // regionArrayCopy.push({ name: 'ALL' });
    // if (this.sharedDataService.dealerData !== undefined) {
    //   this.sharedDataService.dealerData.forEach(element => {
    //     if (element.brand === this.sharedDataService.brandName) {
    //       let data = {
    //         name: element.areaRegionName,
    //         makeCode: element.brand
    //       };
    //       regionArrayCopy.push(data);
    //     }
    //   });
    // }
    // regionArrayCopy = this.sort_by_key(regionArrayCopy, 'name');
    // this.regionItems =
    //   regionArrayCopy.map(item => item.name).filter((item, i, ar) => ar.indexOf(item) === i);

    // let districtArrayCopy = new Array<any>();
    // districtArrayCopy.push({ name: 'ALL' });
    // if (this.sharedDataService.dealerData !== undefined) {
    //   this.sharedDataService.dealerData.forEach(element => {
    //     if (element.brand === this.sharedDataService.brandName) {
    //       let data = {
    //         name: element.districtNo,
    //         makeCode: element.brand
    //       };
    //       districtArrayCopy.push(data);
    //     }
    //   });
    // }
    // districtArrayCopy = this.sort_by_key(districtArrayCopy, 'name');
    // this.districtItems =
    //   districtArrayCopy.map(item => item.name).filter((item, i, ar) => ar.indexOf(item) === i);
  }

  ngOnInit(): void {
    let regionArrayCopy = new Array<any>();
    regionArrayCopy.push({ name: 'ALL' });
    if(this.isRegionalAndDistrictWiseFilterSelected){
      this.myDatePickerOptions = {
        disableWeekends: true,
        disableDateRanges: this.getFutureDateRanges()
      };
    }
    if (this.sharedDataService.dealerData !== undefined) {
      this.sharedDataService.dealerData.forEach(element => {
        if(this.isRegionalAndDistrictWiseFilterSelected){
          if (element.brand === this.sharedDataService.brandName &&  element.areaRegionNo != '83') {
            let data = {
              name: element.areaRegionName,
              makeCode: element.brand
            };
            regionArrayCopy.push(data);
          }
        }
        else{
          if (element.brand === this.sharedDataService.brandName) {
            let data = {
              name: element.areaRegionName,
              makeCode: element.brand
            };
            regionArrayCopy.push(data);
          }
        }
      });
    }
    regionArrayCopy = this.sort_by_key(regionArrayCopy, 'name');
    this.regionItems =
      regionArrayCopy.map(item => item.name).filter((item, i, ar) => ar.indexOf(item) === i);

    let districtArrayCopy = new Array<any>();
    districtArrayCopy.push({ name: 'ALL' });
    if (this.sharedDataService.dealerData !== undefined) {
      this.sharedDataService.dealerData.forEach(element => {
        if (element.brand === this.sharedDataService.brandName) {
          let data = {
            name: element.districtNo,
            makeCode: element.brand
          };
          districtArrayCopy.push(data);
        }
      });
    }
    districtArrayCopy = this.sort_by_key(districtArrayCopy, 'name');
    this.districtItems =
      districtArrayCopy.map(item => item.name).filter((item, i, ar) => ar.indexOf(item) === i);
      
    
  }

  getDistricts(event: any) {
    console.log('55OnChange');
    console.log(event.value);

    let districtArrayCopy = new Array<any>();
    let _regionArrayCopy = new Array<any>();
    districtArrayCopy.push({ name: 'ALL' });
    if (this.sharedDataService.dealerData !== undefined) {
      this.sharedDataService.dealerData.forEach(element => {
        if (element.brand === this.sharedDataService.brandName) {
          if (element.areaRegionName === event.value) {
            let data = {
              name: element.districtNo,
              makeCode: element.brand
            };
            districtArrayCopy.push(data);
          }
        }
      });
    }
    districtArrayCopy = this.sort_by_key(districtArrayCopy, 'name');
    this.districtItems =
      districtArrayCopy.map(item => item.name).filter((item, i, ar) => ar.indexOf(item) === i);


    if (this.isRegionalAndDistrictWiseFilterSelected) {
      this.filterForReginalWise();
    }

  }

  sort_by_key(array, key) {
    return array.sort(function (a, b) {
      if (b[key] === 'ALL') return 1;
      let x = a[key]; let y = b[key];
      return ((x < y) ? -1 : ((x > y) ? 1 : 0));
    });
  }

  viewInventory() {
    this.viewInventoryEvent.emit(this.enteredDealerCode);
  }

  findDealer() {
    this.findDealerEvent.emit();
  }

  findVehicle() {
    this.enteredvin = this.enteredvin.toUpperCase().trim();
    this.findVehicleEvent.emit(this.enteredvin);
  }

  filterValueChanged() {
    let dateValues = {
      filter: 'between',
      fromDate: this.fromDate,
      toDate: this.toDate,
      region: this.regionSelection,
      district: this.districtSelection
    };

    // this.filterDateEvent.emit(data);
    this.filterDateEvent.emit(dateValues);

  }

  filterForReginalWise() {
    this.selctedRegionForRegionaDistrictWiseEvent.emit(
      {
        region: this.regionSelection
      })
  }

  dateFilter(event: any, value: any) {

    if (value === 'fromDate') {
      this.fromDate = event.formatted;
    }

    if (value === 'toDate') {
      this.toDate = event.formatted;
    }

    if (value === 'regionSelection') {
      this.regionSelection = event.region;
    }

    if (value === 'districtSelection') {
      this.districtSelection = event.district;
    }

    if (this.fromDate !== undefined && this.toDate !== undefined) {
      this.isDateSelected = true;
    }
    if (this.isRegionalAndDistrictWiseFilterSelected && this.fromDate !== undefined)
      this.isDateSelected = true;
  }

  getFutureDateRanges(): IMyDateRange[] {
    const today = new Date();
    const futureDateRanges: IMyDateRange[] = [];

    // Create a date range starting tomorrow and going far into the future.
    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);

    const futureRange: IMyDateRange = {
      begin: { year: tomorrow.getFullYear(), month: tomorrow.getMonth() + 1, day: tomorrow.getDate() },
      end: { year: 2100, month: 12, day: 31 } // Some distant future date.
    };

    futureDateRanges.push(futureRange);
    return futureDateRanges;
  }
  
  
}


